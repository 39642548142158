
































import { Action, Getter } from 'vuex-class';
import { Component, Vue, Watch } from 'vue-property-decorator';
// @ts-ignore
import { debounce } from 'lodash';
function Debounce(delay: number) {
    return (target: any, prop: string) => {
        return {
            configurable: true,
            enumerable: false,
            value: debounce(target[prop], delay),
        };
    };
}
// @ts-ignore
import { cloneDeep } from 'lodash';

@Component({
    components: {
        ModalBase: () => import('@/components/elements/Modal.vue'),
        FormInput: () => import('@/components/forms/Input.vue'),
        CompanySearchDropdown: () => import('@/components/company/CompanySearchDropdown.vue'),
        FormButton: () => import('@/components/forms/Button.vue'),
    },
})
export default class SupervisorOrderlistModal extends Vue {
    @Action('company/index') indexCompanies!: (payload: { q: string; per_page: string }) => Promise<Company[]>;
    @Action('company/update') updateCompany!: (payload: Company) => Promise<Company>;
    @Action('product/orderList') getProducts!: (payload: {
        company_slug: string;
        no_set?: boolean;
        collection_slug?: string;
        per_page?: string;
        after?: Array<string | number>;
        q?: string;
        ids?: number[];
    }) => Promise<Product[]>;
    @Action('product/setOrderList') setOrderList!: (payload: Product[]) => Promise<Product[]>;
    @Action('collection/update') updateCollection!: (payload: Collection) => Promise<Collection>;
    @Action('collection/setViewing') setViewing!: (payload: Collection) => void;
    @Action('collection/read') getCollection!: (payload: { id: number; no_set?: boolean }) => Promise<Collection>;

    @Getter('collection/viewing') viewingCollection!: Collection;
    @Getter('company/viewing') viewingCompany!: Collection;

    i18n: string = 'supervisor.order_list.take_over_modal';

    showDropdown: boolean = false;
    localCompanies: Company[] = [];
    searchText: string = '';
    selectedCompany: Company | null = null;

    loading: boolean = false;
    success: boolean = false;
    serverError: ErrorResponse | boolean = false;

    @Debounce(500)
    searchInput(val: string) {
        this.indexCompanies({ q: val, per_page: '4' })
            .then((response: Company[]) => {
                this.showDropdown = true;
                this.localCompanies = response;
            })
            .catch((e: ErrorResponse) => (this.serverError = e));
    }

    @Watch('companies', { immediate: true })
    productsChanged(val: Company[]) {
        this.localCompanies = val;
    }

    companySelected(company: Company) {
        this.searchText = company.name ? company.name : 'Naamloos';
        this.showDropdown = false;
        this.selectedCompany = company;
    }

    takeOverCollection() {
        if (this.selectedCompany) {
            this.loading = true;

            this.getCollection({ id: this.selectedCompany?.collection_id, no_set: true }).then((reponse: Collection) => {
                const blueprintCollection = reponse;
                const perPage = blueprintCollection.total_products;

                if (blueprintCollection.slug && perPage) {
                    this.getProducts({ company_slug: blueprintCollection.slug, no_set: true, per_page: perPage.toString() }).then((products: Product[]) => {
                        const productIds = products
                            .map((p) => {
                                return p.id;
                            })
                            .filter((element) => {
                                return element !== undefined;
                            });

                        const payload: Collection = cloneDeep(this.viewingCollection);

                        payload.products = productIds;

                        this.updateCollection(payload).then((createdCollection: Collection) => {
                            if (createdCollection && createdCollection.id) {
                                const updatedCompany: Company = cloneDeep(this.viewingCompany);
                                updatedCompany.collection_id = createdCollection.id;

                                this.updateCompany(updatedCompany).then(() => {
                                    this.setOrderList(products);
                                    this.setViewing(createdCollection);
                                    this.loading = false;
                                    this.success = true;
                                    this.$emit('close');
                                    this.$toasted.show(`Bestellijst overgenomen van ${this.selectedCompany?.name}`, {
                                        position: 'bottom-left',
                                        duration: 5000,
                                        action: {
                                            text: '',
                                            class: 'close',
                                            onClick: (e, toastObject) => {
                                                toastObject.goAway(0);
                                            },
                                        },
                                    });
                                });
                            }
                        });
                    });
                }
            });
        }
    }
}
