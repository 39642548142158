





















import { Getter, Action } from 'vuex-class';
import { Component, Vue, Prop } from 'vue-property-decorator';
// @ts-ignore
import { cloneDeep } from 'lodash';

@Component({
    components: {
        ModalBase: () => import('@/components/elements/Modal.vue'),
        FormButton: () => import('@/components/forms/Button.vue'),
    },
})
export default class SupervisorDeleteProductModal extends Vue {
    @Prop() product!: Product;
    @Getter('collection/viewing') collection!: Company;

    @Action('collection/update') updateCollection!: (payload: Collection) => Promise<Collection>;
    @Action('product/setOrderList') setOrderList!: (payload: Product[]) => Promise<Product[]>;

    i18n: string = 'supervisor.order_list.clear_order_list_modal';

    loading: boolean = false;
    success: boolean = false;
    serverError: ErrorResponse | boolean = false;

    clearOrderList() {
        this.loading = true;

        const payload: Collection = cloneDeep(this.collection);
        payload.products = [];

        this.updateCollection(payload).then((collection: Collection) => {
            this.setOrderList([]);
            this.loading = false;
            this.success = true;
            this.$emit('close');
            this.$toasted.show('Bestellijst leeggemaakt', {
                position: 'bottom-left',
                duration: 5000,
                action: {
                    text: '',
                    class: 'close',
                    onClick: (e, toastObject) => {
                        toastObject.goAway(0);
                    },
                },
            });
        });
    }
}
