

































import { Getter, Action } from 'vuex-class';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
// @ts-ignore
import { cloneDeep } from 'lodash';
// @ts-ignore
import { debounce } from 'lodash';
function Debounce(delay: number) {
    return (target: any, prop: string) => {
        return {
            configurable: true,
            enumerable: false,
            value: debounce(target[prop], delay),
        };
    };
}

@Component({
    components: {
        ModalBase: () => import('@/components/elements/Modal.vue'),
        FormButton: () => import('@/components/forms/Button.vue'),
        FormInput: () => import('@/components/forms/Input.vue'),
        FormCheckbox: () => import('@/components/forms/Checkbox.vue'),
        ProductSample: () => import('@/components/product/ProductSample.vue'),
    },
})
export default class SupervisorAddProductModal extends Vue {
    @Getter('company/viewing') company!: Company;
    @Getter('collection/viewing') collection!: Collection;

    @Action('product/index') indexAll!: (payload: { q: string; exclude_collection: number; per_page: string }) => Promise<Product[]>;
    @Action('product/orderListById') orderListById!: (payload: { id: number; collection_slug?: string; no_set?: boolean }) => Promise<Product[]>;
    @Action('collection/patch') patchCollection!: (payload: { id: number; products: Array<{ id: number; action: string }> }) => Promise<Collection>;

    @Prop() category: any;

    localProducts: Product[] = [];

    i18n: string = 'supervisor.order_list.add_product_modal';

    searchText: string = '';

    selectedProducts: Product[] = [];

    loading: boolean = false;
    success: boolean = false;
    serverError: ErrorResponse | boolean = false;

    addProductSubmit() {
        this.loading = true;

        if (this.selectedProducts.length && this.collection != null) {
            const payload: { id: number; products: Array<{ id: number; action: string }> } = { id: this.collection.id ?? 0, products: this.selectedProducts.map((p: Product) => ({ id: p.id, action: 'add' })) };

            this.patchCollection(payload)
                .then(() => {
                    this.loading = false;
                    this.success = true;
                    this.$emit('close');

                    this.orderListById({
                        id: this.company.collection_id,
                        collection_slug: this.category && this.category.key ? this.category.key : null,
                    });

                    this.$toasted.show('Product(en) toegevoegd aan bestellijst', {
                        position: 'bottom-left',
                        duration: 5000,
                        action: {
                            text: '',
                            class: 'close',
                            onClick: (e, toastObject) => {
                                toastObject.goAway(0);
                            },
                        },
                    });
                })
                .catch((e: ErrorResponse) => (this.serverError = e));
        }
    }

    @Debounce(500)
    searchInput(val: string) {
        if (val === '') {
            this.localProducts = [];
        } else if (this.collection && this.collection.id) {
            this.indexAll({ q: val, exclude_collection: this.collection.id, per_page: '50' })
                .then((response: Product[]) => {
                    this.localProducts = response;
                })
                .catch((e: ErrorResponse) => (this.serverError = e));
        }
    }

    toggleProduct(product: Product) {
        if (this.selectedProducts.includes(product)) {
            this.selectedProducts.splice(this.selectedProducts.indexOf(product), 1);
        } else {
            this.selectedProducts.push(product);
        }
    }

    @Watch('products', { immediate: true })
    productsChanged(val: Product[]) {
        this.localProducts = val;
    }

    productSelected(product: Product) {
        this.searchText = product.name ? product.name : 'Naamloos';
        this.selectedProducts.push(product);
    }
}
