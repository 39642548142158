





















import { Getter, Action } from 'vuex-class';
import { Component, Vue, Prop } from 'vue-property-decorator';
// @ts-ignore
import { cloneDeep } from 'lodash';

@Component({
    components: {
        ModalBase: () => import('@/components/elements/Modal.vue'),
        FormButton: () => import('@/components/forms/Button.vue'),
    },
})
export default class SupervisorDeleteProductModal extends Vue {
    @Prop() product!: Product;
    @Getter('collection/viewing') collection!: Company;

    @Action('product/patch') patchProduct!: (payload: { id: number; collections: Array<{ id: number; action: string }>; removeFromOrderlist?: boolean }) => Promise<Product>;

    i18n: string = 'supervisor.order_list.delete_product_modal';

    loading: boolean = false;
    success: boolean = false;
    serverError: ErrorResponse | boolean = false;

    deleteProduct() {
        if (this.product != null && this.collection != null) {
            this.loading = true;
            const payload: Product = cloneDeep(this.product);
            const collectionId = this.collection.id;

            const idIndex = payload.collections.indexOf(collectionId);

            if (idIndex >= 0) {
                payload.collections.splice(idIndex, 1);

                this.patchProduct({ id: this.product.id ?? 0, collections: [{ id: collectionId ?? 0, action: 'remove' }], removeFromOrderlist: true }).then((response: Product) => {
                    this.loading = false;
                    this.success = true;
                    this.$emit('close');
                    this.$toasted.show('Product verwijderd uit bestellijst', {
                        position: 'bottom-left',
                        duration: 5000,
                        action: {
                            text: '',
                            class: 'close',
                            onClick: (e, toastObject) => {
                                toastObject.goAway(0);
                            },
                        },
                    });
                });
            }
        }
    }
}
