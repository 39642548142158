




















































































































































import { Getter, Action } from 'vuex-class';
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
// @ts-ignore
import { debounce } from 'lodash';
function Debounce(delay: number) {
    return (target: any, prop: string) => {
        return {
            configurable: true,
            enumerable: false,
            value: debounce(target[prop], delay),
        };
    };
}
import OrderListModal from '@/components/supervisor/OrderListModal.vue';
import AddProductModal from '@/components/supervisor/AddProductModal.vue';
import DeleteProductModal from '@/components/supervisor/DeleteProductModal.vue';
import ClearOrderListModal from '@/components/supervisor/ClearOrderListModal.vue';

@Component({
    components: {
        ElButton: () => import('@/components/elements/Button.vue'),
        Spinner: () => import('@/components/elements/Spinner.vue'),
        // @ts-ignore
        VSelect: () => import('vue-select'),
        TableBase: () => import('@/components/table/TableBase.vue'),
    },
})
export default class SupervisorOrderList extends Vue {
    @Getter('product/orderList') products!: Product[];
    @Getter('pageLoading') pageLoading!: boolean;

    @Action('product/orderListById') orderList!: (payload: {
        id: number;
        collection_slug?: string;
        created_at_sort?: string | null;
        after?: Array<string | number> | null;
        q?: string;
        ids?: number[];
    }) => Promise<Order[]>;
    @Action('product/read') readProduct!: (payload: { slug: string; no_set?: boolean }) => Promise<Product>;

    i18n: string = 'views.company';

    @Prop() collectionId?: number;

    searchString: string = '';
    dropdownVisible: boolean = false;

    localProducts: Product[] = [];

    $refs!: {
        productList: HTMLElement;
    };

    tableLayout: object = {
        settings: {
            locale_path: `${this.i18n}.table`,
            max_height: 'calc(100% - 110px)',
            clickable: true,
        },
        structure: [
            {
                key: this.$t(`${this.i18n}.order_list.table.columns.name`),
                value: (model: Product) => model.name,
                width: 500,
            },
            {
                key: this.$t(`${this.i18n}.order_list.table.columns.id`),
                value: (model: Product) => model.product_no,
                width: 75,
            },
            {
                key: '',
                value: (model: Product) => '<span class="text-blue-dark border-blue-dark hover:border-transparent transition-colors border-b">Verwijderen</span>',
            },
        ],
    };

    categoryOptions = [
        {
            key: '6-paint',
            label: 'Paint',
        },
        {
            key: '7-non-paint',
            label: 'Non-paint',
        },
        {
            key: '8-readymix',
            label: 'ReadyMix',
        },
        {
            key: '10-spuitbussen',
            label: 'Spuitbussen',
        },
        {
            key: '4-uitverkoop',
            label: 'Uitverkoop',
        },
    ];

    filter = {
        searchString: '',
        category: '',
    };

    mounted() {
        this.$nextTick(() => {
            const self = this;
            this.initProductList();
            window.addEventListener('resize', () => self.initProductList());
            window.addEventListener('orientationchange', () => self.initProductList());
        });
    }

    initProductList() {
        window.addEventListener('scroll', this.productScroll);
    }

    productScroll(e: Event) {
        const productList: HTMLElement | undefined = this.$refs.productList;
        const vw = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0);

        if (productList) {
            if (window.scrollY + vw >= productList.getBoundingClientRect().bottom + window.scrollY) {
                this.loadMore(true);
            }
        }
    }

    @Watch('products', { immediate: true })
    productsChanged(val: Product[]) {
        this.localProducts = val;
    }

    @Debounce(500)
    loadMore(after: boolean) {
        if (this.collectionId) {
            this.orderList({
                id: this.collectionId,
                after: after ? this.lastAfter : null,
                // @ts-ignore
                collection_slug: this.filter && this.filter.category && this.filter.category.key ? this.filter.category.key : null,
                // @ts-ignore
                created_at_sort: this.filter && this.filter.sort && this.filter.sort.key && this.filter.sort.key.indexOf('created_at') === 0 ? this.filter.sort.key.replace('created_at_', '') : null,
                q: this.filter.searchString,
            });
        }
    }

    deleteProduct(product: Product) {
        if (product.slug) {
            this.readProduct({ slug: product.slug, no_set: true }).then((response: Product) => {
                this.$modal.show(DeleteProductModal, { product: response }, { adaptive: true, width: '500', height: 'auto' });
            });
        }
    }

    clearTextFilter() {
        this.filter.searchString = '';
        this.loadMore(false);
    }

    private handleSearch() {
        if (!this.searchString) {
            return;
        }
    }

    private takeOverOrderList() {
        this.$modal.show(OrderListModal, undefined, { adaptive: true, width: '500', height: 'auto' });
    }

    private clearOrderList() {
        this.$modal.show(ClearOrderListModal, undefined, { adaptive: true, width: '500', height: 'auto' });
    }

    private addProduct() {
        this.$modal.show(AddProductModal, { category: this.filter.category }, { adaptive: true, width: '700', height: '740' });
    }

    get lastAfter(): Array<string | number> {
        const total = this.localProducts.length;
        const last: Product = this.localProducts[total - 1];
        if (last && last.sort) {
            return last.sort;
        }

        return [];
    }
}
